
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate();
  const [isFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    const element = document.documentElement;

    if (!isFullScreen) {
      // Request full screen
      if (element.requestFullscreen) {
        element.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen().catch((err) => {
          console.error('Error attempting to enable full screen:', err.message);
        });
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().catch((err) => {
          console.error('Error attempting to exit full screen:', err.message);
        });
      }
    }

    // Toggle the state
    // setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    setTimeout(handleFullScreenToggle, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  document.addEventListener('fullscreenchange', () => {
    if (document.fullscreenElement)
      console.info('We are fullscreen!!!')
    else
      console.info('Do nothing...')
  })

  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem('sessionTimer', new Date());
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let time = localStorage.getItem('sessionTimer');
      if (!time) {
        localStorage.setItem('sessionTimer', new Date());
        time = new Date();
      } else {
        time = new Date(time);
      }
      let diffTime = new Date().getTime() - time.getTime();
      const lifetime = 0.5;
      const lateTokenTime = (lifetime * 60) - (diffTime / 1000);
      if (lateTokenTime < 1) {
        navigate('/')
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header className="sticky top-0 bg-gradient-to-r from-primary-100 from-0% via-primary-500 via-60% to-primary-100 to-100% shadow z-50">
      <nav className="mx-auto flex items-center justify-center p-1 lg:px-8" aria-label="Global">
        <div className="flex justify-start h-[100px]">
          <Link to={'/'} className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-[100px] w-auto" src="/mecore-logos/main-logo/logo.png" alt="" />
          </Link>
        </div>
        <div className="text-center h-12 font-bold text-5xl text-white ml-4">
          ОРХОН АЙМГИЙН ТӨРИЙН АРХИВ
        </div>
      </nav>
    </header>
  )
}

export default Header